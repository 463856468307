.landing-type {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: var(--light-shades);
  background-color: rgba(0, 0, 0, 0.2);
  padding: var(--padding);
  border-radius: var(--border-radius);
  backdrop-filter: blur(10px);
}