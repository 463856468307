.animated-section {
  background-color: var(--dark-shades);
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.animated-section .animated-row {
  position: relative;
  top: -60%;
  left: -5%;
  width: 100%;
  display: flex;
  padding: 10px 0;
  white-space: nowrap;
  transform: rotate(-30deg);
}

.animated-section svg {
  width: 150px;
  height: 150px;
  fill: rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  margin: 0 5px;
}

.animated-section svg:hover {
  fill: var(--main-brand);
  filter: drop-shadow(0 0 15px var(--main-brand));
}

.animated-section .animated-row div {
  animation: animate 80s linear infinite;
  animation-delay: -80s;
}

.animated-section .animated-row div:nth-child(2) {
  animation: animate2 80s linear infinite;
  animation-delay: -40s;
}


@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
  }
}

.animated-section .animated-row:nth-child(even) div {
  animation: animate3 80s linear infinite;
  animation-delay: -80s;
}

.animated-section .animated-row:nth-child(even) div:nth-child(2) {
  animation: animate4 80s linear infinite;
  animation-delay: -40s;
}

@keyframes animate3 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes animate4 {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0%);
  }
}
